import React, { PureComponent } from 'react';
import { toast } from "react-toastify";
import ChatContext from '../../components/ChatContext';
// import db from "../../../../config/firebase";
//import { mailUrl } from "../../../../config/mailFunctions.js";
import { ENTER_EMAIL, MAIL_FUNCTION_URL, GATEWAY_URL, FUNCTION_API_KEY, DESK_API_URL, commonLoginApi } from '../../../../config/siteConfig';
import { getTimeFromDate, validateEmail, nameIntials, isCallingEnable, removeHtmlTags, CHAT_TYPES } from '../../components/Comman';
import { startCall, startVoipCall, endCall, setCallingStatus } from '../../components/Twilio';
import { getPlanData } from '../../components/Dblovefield';
import CobrowseAPI from 'cobrowse-agent-sdk';
import ReactTooltip from "react-tooltip";
import firebaseServices from '../../../../firebase';
const cobrowse = new CobrowseAPI()
const MAX_COBROWSING_TIME = 300000; // 5 minutes max
class Header extends PureComponent {
    static contextType = ChatContext;  // this.context will work when it defined as is

    // To use global data pass second parameter context in constructor
    constructor(props, context) {
        super(props);
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.canCalling = isCallingEnable(this.agent);
        this.state = {
            closeOption: 0,
            isEditClientName: false,
            isEditClientEmail: false,
            isEditClientCompany: false,
            changeClientName: "",
            changeCompanyName: "",
            changeClientEmail: "",
            groupChatAgents: [],
            liveChatDocId: "",
            clientPlan: false,
            liveChatDocId2: "",
            isCoBrowsingAvailable: true,
            loader: false,
            deskUserId: '',
            ticketingEnabled: false,
            isAutoTicketing: false,
            deskPaymentStatus: false,
            cobrowsingdevicesConnected: [],
            cobrowsingEnabled: false,
            totalCobrowsingLicense: 1,
            microphoneAccess: false,
            showMicrophoneAccessModal: false,
            closeMicrophoneNotification: true,
        };
    }



    componentDidMount = () => {

        ReactTooltip.rebuild()
        this.checkMicrophoneAccess()

        try {
            firebaseServices.db.collection('users').doc(this.agent.ownerId).get().then(user => {
                if (user.exists) {
                    let cobrowsingEnabled = user.data().cobrowsingEnabled ? user.data().cobrowsingEnabled : false;
                    this.setState({ cobrowsingEnabled: cobrowsingEnabled });
                    return cobrowsingEnabled;
                } else {
                    return false;
                }
            }).then((cobrowsingEnabled) => {
                if (cobrowsingEnabled) {
                    firebaseServices.db.collection('cobrowsing').doc('status').onSnapshot((docSnapshot) => {
                        let statusData = docSnapshot.data();
                        this.setState({ isCoBrowsingAvailable: statusData.isCoBrowsingAvailable, cobrowsingdevicesConnected: statusData.devicesConnected ? statusData.devicesConnected : [], totalCobrowsingLicense: statusData.totalCobrowsingLicense })
                    })
                }
            })
        } catch (err) { console.log(err); }
        try {
            this.getUserId();
        } catch (err) { console.log(err); }
    }



    getCookie = (cname) => {
        var name = cname + "=";
        var docCookie = unescape(document.cookie);
        var decodedCookie = decodeURIComponent(docCookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    getUserId = () => {
        let ID =this.getCookie('APPYID');
        fetch(commonLoginApi + "api/common/getuserdetailnew", { // API to get desk userID from commonlogin kdapi
            method: "post",
            body: JSON.stringify({"file_name":ID}),
            headers: {
                "Authorization": "Bearer YXBwbGljYXRpb246c2VjcmV0",
                "Content-Type": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json())
            .then(response => {        
                       
                if ((response.status) == 200) {
                    // let userId = '';
                    // response.userdata.apps_access.forEach((apps) => {
                    //     if (apps.product === 'helpdesk') {
                    //         userId = apps.user_id;
                    //     }
                    // })
                     this.checkDeskPaymentStatus()
                    
                }
                else {
                    this.setState({
                        deskUserId: '',
                        ticketingEnabled: false,
                        isAutoTicketing: false,
                        deskPaymentStatus: false
                    });
                    this.context.updateGlobalData("isAutoTicketing", false);
                    return false;
                }
            }).catch(error => console.log('error', error));
    }

    // API to check desk payment status
    checkDeskPaymentStatus = (userId) => {
        fetch(DESK_API_URL + "/openTicketApi/api/isPaymentDone", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Origin": window.location.origin
            },
            body: JSON.stringify({
                "user_id": localStorage.deskId  
            }),
        }).then(res => res.json())
            .then(response => {
                if (response.data[0].checkoutUrl) { // if checkoutUrl is received, payment is incomplete
                    this.deskPaymentLink = response.data[0].checkoutUrl;
                    this.setState({ deskPaymentStatus: false });
                    return false;
                }
                else if (response.data[0].payment_detail.payment_status === 'complete') {
                    this.setState({ deskPaymentStatus: true });
                    return true;
                }
            }).then(res => {
                if (res) { // if desk payment status is true check position of switch from DB
                    firebaseServices.db.collection('users').doc(this.agent.ownerId).get().then(user => {
                        if (user.exists) {
                            let ticketingEnabled = user.data().ticketingEnabled ? user.data().ticketingEnabled : false;
                            this.setState({ticketingEnabled :ticketingEnabled})
                            let isAutoTicketing = ticketingEnabled ? user.data().ticketingSettings.isAutoTicketing : false;
                            this.setState({  isAutoTicketing: isAutoTicketing });
                            this.context.updateGlobalData("isAutoTicketing", isAutoTicketing);
                        } else {
                            this.setState({ ticketingEnabled: false, isAutoTicketing: false });
                            this.context.updateGlobalData("isAutoTicketing", false);
                        }
                    }).catch(err => {
                        console.log('Error while getting data of user from DB-->', err);
                    })
                } else { // if desk payment status is false, set switch to OFF
                    this.setState({ ticketingEnabled: false, isAutoTicketing: false });
                    this.context.updateGlobalData("isAutoTicketing", false);
                }
            }).catch(error => console.log('error', error));
    }

    /*Twilio Function Start */

    startCall = () => {
        if (!this.canCalling) {
            return false;
        }
        this.checkMicrophoneAccess()
        if(!this.state.microphoneAccess){
            this.setState({showMicrophoneAccessModal: true});
            return false;
        }
        var agentProfile = encodeURIComponent(this.agent.profile_img);
        var tmpmsgContent = encodeURIComponent("Call started</small><br/>Your call will be recorded for quality purpose.");
        var body = [`ownerId=${this.agent.ownerId}&chatId=${this.context.currentVisitor.id}&agentId=${this.agent.agentId}&agentName=${this.agent.name}&agentProfile=${agentProfile}&agentShort=${nameIntials(this.agent.name)}&msg=${tmpmsgContent}&msg_type=12&msg_url=&contenttype=&filesize=0&action=addmessage`];
        //fetch(MAIL_FUNCTION_URL + "/saveMessage", {
        fetch(GATEWAY_URL + "/saveMessage", {
            method: "post",
            body: body,
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json())
            .then(response => {
                if (response.result === "success") {
                    let dataObj2 = {
                        msgId: response.chatId,
                        ownerId: this.agent.ownerId,
                        chatId: this.context.liveChatDocId
                    };
                    startCall(this.context.currentVisitor.client_phone, this.agent, 'header', dataObj2);
                }
            }).catch((err) => {
                //console.log("ERROR!", err);
            });
    }

    checkMicrophoneAccess = async () => {
        console.log("checking....microphone")
        try {
            // Request microphone access
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // Microphone access granted
            this.setState({ microphoneAccess: true });

            // Release the microphone access
            stream.getTracks().forEach((track) => {
                track.stop();
            });
        } catch (error) {
            // Microphone access not granted
            this.setState({ microphoneAccess: false });
        }
    };

    closeModal = () => {
        this.setState({ showMicrophoneAccessModal: false });
    }

    startVoipCall = () => {

        if (!this.canCalling) {
            return false;
        }
        this.checkMicrophoneAccess()
        if (!this.state.microphoneAccess) {
            this.setState({ showMicrophoneAccessModal: true });
            return false
        }

        let hideCallOption = (this.context.currentVisitor.deviceUsed === 'Android Browser' || this.context.currentVisitor.deviceUsed === 'Android App' || this.context.currentVisitor.deviceUsed === 'iOS Browser' || this.context.currentVisitor.deviceUsed === 'iOS App') ? true : false;
        if (hideCallOption) {
            toast.error("ERROR : Cannnot initiate VoIP call on chats from mobile apps.");
            return false;
        }

        var agentProfile = encodeURIComponent(this.agent.profile_img);
        var tmpmsgContent = encodeURIComponent("Call started</small><br/>Your call will be recorded for quality purpose.");
        var body = [`ownerId=${this.agent.ownerId}&chatId=${this.context.currentVisitor.id}&agentId=${this.agent.agentId}&agentName=${this.agent.name}&agentProfile=${agentProfile}&agentShort=${nameIntials(this.agent.name)}&msg=${tmpmsgContent}&msg_type=12&msg_url=&contenttype=&filesize=0&action=addmessage`];
        ////fetch(MAIL_FUNCTION_URL + "/saveMessage", {
        fetch(GATEWAY_URL + "/saveMessage", {
            method: "post",
            body: body,
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json())
            .then(response => {
                if (response.result === "success") {
                    let dataObj2 = {
                        msgId: response.chatId,
                        ownerId: this.agent.ownerId,
                        chatId: this.context.liveChatDocId
                    };
                    var isVoipCall = this.context.currentVisitor.start_url === "voip_call" ? true : false;
                    if (isVoipCall) {
                        let allagentsIdes = this.context.currentVisitor.agent_ids;
                        let fndIndx = allagentsIdes.indexOf(this.agent.agentId);
                        if (fndIndx !== -1) {
                            allagentsIdes.splice(fndIndx, 1);
                        }
                        try {
                            startVoipCall(allagentsIdes[0], this.agent, 'header', dataObj2);
                        } catch (err) { console.log(err) }
                    } else {
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).collection('typing_status').doc(this.agent.name).set({ isCalling: true });
                        startVoipCall(this.context.liveChatDocId, this.agent, 'header', dataObj2);
                    }
                }
            }).catch((err) => { });
    }

    endCall = () => {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).collection('typing_status').doc(this.agent.name).set({ isCalling: false });
        endCall();
    }

    showWhatsAppTemplates = () => {
        var myArr = [{ key: "isCloseBtnClicked", value: "" }, { key: "rightSectionItemToShow", value: "whatsapptemplates" }];
        this.context.updateGlobalData("array", myArr);
    }

    /*Twilio Function End */

    componentDidUpdate = async (prevProps, prevState, snapshot) => {

        ReactTooltip.rebuild();

        if (Object.keys(this.context.currentVisitor).length > 0) {

            if (this.context.currentVisitor.agent_ids.length !== this.state.groupChatAgents.length || this.state.liveChatDocId !== this.context.liveChatDocId) {
                this.state.liveChatDocId = this.context.liveChatDocId;
                this.getGroupChatAgents();
            }

            if (this.state.liveChatDocId2 !== this.context.liveChatDocId || this.context.startClickedChatId === this.state.liveChatDocId2) {
                this.state.liveChatDocId2 = this.context.liveChatDocId;
                this.state.clientPlan = false;
                if (this.context.startClickedChatId === this.state.liveChatDocId2) {
                    this.context.updateGlobalData("startClickedChatId", "");
                    setTimeout(async () => {
                        let clientPlan = await getPlanData(this.context.liveChatDocId);
                        this.setState({ clientPlan: clientPlan });
                    }, 3000);
                } else {
                    let clientPlan = await getPlanData(this.context.liveChatDocId);
                    this.setState({ clientPlan: clientPlan });
                }
            }

            if (this.context.currentVisitor.agent_ids.length !== this.state.groupChatAgents.length && this.context.isCloseBtnClicked === "leavenow") {
                if (this.context.currentVisitor.agent_ids.length === 1) {
                    this.context.updateGlobalData("isCloseBtnClicked", "yes");
                }
            }
        }
    }

    getGroupChatAgents = () => {
        try {
            var promises = [];
            for (let i = 0; i < this.context.currentVisitor.agent_ids.length; i++) {
                promises.push(firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("agents").doc(this.context.currentVisitor.agent_ids[i]).get());
            }
            Promise.all(promises).then(allResult => {
                var allAgents = [];
                for (const index in allResult) {
                    const aData = allResult[index];
                    const Agent = {
                        id: aData.id,
                        agent_short: aData.data().agent_short,
                        alias: aData.data().alias,
                        email: aData.data().email,
                        name: aData.data().name,
                        profile_img: aData.data().profile_img
                    };
                    allAgents.push(Agent);
                }
                this.setState({ groupChatAgents: allAgents });
            });

        } catch (err) { console.log("error"); }
    }

    confirmEndChat = () => {
        if (this.context.rightSectionItemToShow === "ticketing" && this.context.chatPickStatusTransaction.status === 'close') {
            this.context.updateGlobalData("showDeskTicketPopUp", true);
        } else if (this.context.chatPickStatusTransaction.status === 'close') {
            this.context.updateGlobalData("isCloseBtnClicked", "close");
        } else {
            let vData = this.context.currentVisitor ? this.context.currentVisitor : {};
            var visitor_status = vData.visitor_status;
            var agent_ids = vData.agent_ids;
            if (visitor_status === "ongoing" && agent_ids.length > 1 && agent_ids.indexOf(this.agent.agentId) !== -1 && vData.start_url !== "voip_call") {
                this.context.updateGlobalData("isCloseBtnClicked", "leavenow");
            } else if (vData.id.indexOf("TEMP") !== -1) {
                this.context.updateGlobalData("isCloseBtnClicked", "close");
            } else {
                this.context.updateGlobalData("isCloseBtnClicked", "yes");
            }

            if (this.context.incoming_guide === false && this.context.incoming_guide_step === 2) {
                this.guidenextStep('incoming', 3, 'incoming_guide_step')
            }
        }
    }

    showTranslater = () => {
        if (this.context.chatPickStatusTransaction.status !== "close") {
            var allTranslaters = {};
            var tmpData = this.context.chatTranslator ? this.context.chatTranslator : {};

            if (Object.keys(tmpData).length > 0) {
                for (var key in tmpData) {
                    if (tmpData.hasOwnProperty(key)) {
                        allTranslaters[key] = tmpData[key];
                    }
                }
            }

            if (Object.keys(allTranslaters).length > 0) {
                if (allTranslaters.hasOwnProperty(this.context.liveChatDocId)) {
                    if (allTranslaters[this.context.liveChatDocId].status === true) {
                        return false;
                    } else {
                        allTranslaters[this.context.liveChatDocId] = { "status": true, "translating": false };
                        this.context.updateGlobalData("chatTranslator", allTranslaters);
                    }

                } else {
                    allTranslaters[this.context.liveChatDocId] = { "status": true, "translating": false };
                    this.context.updateGlobalData("chatTranslator", allTranslaters);
                }
            } else {
                this.context.updateGlobalData("chatTranslator", { [this.context.liveChatDocId]: { "status": true, "translating": false } });
            }
        }
    }

    showOnlineAgents = () => {
        if (this.context.currentVisitor.agent_ids.length > 1) {
            toast.error("ERROR : Group chat can not be transfered.");
            return false;
        } else {
            var myArr = [{ key: "isCloseBtnClicked", value: "" }, { key: "rightSectionItemToShow", value: "transferAgent" }];
            this.context.updateGlobalData("array", myArr);
        }
    }

    showPastChat = () => {
        var myArr = [{ key: "showPastChat", value: true }]
        this.context.updateGlobalData("array", myArr);
    }

    banUser = () => {
        var myArr = [{ key: "showBanUser", value: true }]
        this.context.updateGlobalData("array", myArr);
    }

    showAvailableAgents = () => {
        var myArr = [{ key: "isCloseBtnClicked", value: "" }, { key: "rightSectionItemToShow", value: "addAgent" }];
        this.context.updateGlobalData("array", myArr);
        try {
            var checkboxes = document.getElementsByClassName('addAgentCheckbox');
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
        } catch (err) {

        }
    }

    /* Send Email transcript to current visitor start */

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    emailTranscript = () => {

        if (this.context.goneOffline) {
            return false;
        }

        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.context.liveChatDocId).collection('chat_data').orderBy('addedon', 'asc').get().then(chatData => {
            var totalChats = chatData.size;
            if (totalChats) {
                var finalChatData = "<table>";
                var email = this.agent.email;

                /// loop start ////////////
                for (const index in chatData.docs) {
                    const change = chatData.docs[index];
                    const docData = change.data();
                    const {
                        addedon,
                        agent_id,
                        msg,
                        msg_url,
                        agent_name
                    } = docData;
                    const msg_type = docData.msg_type ? docData.msg_type : 'NA';
                    var time = getTimeFromDate(addedon);

                    if (parseInt(index) === 0) {
                        finalChatData += '<tr><td colspan="2">Chat Started on ' + time + '</td></tr>';
                    }
                    var messageWithSender = '';
                    if (msg_type === 6) {
                        messageWithSender = '*****' + msg + '*****';
                        finalChatData += '<tr><td>(' + time + ')</td><td>' + messageWithSender + '</td></tr>';
                    } else if (msg_type === 8) {
                        if (msg.length > 0) {
                            msg.forEach(data => {
                                finalChatData += '<tr><td>(' + time + ')</td><td> BOT : Q : ' + data.Q + '</td></tr>';
                                finalChatData += '<tr><td>(' + time + ')</td><td> BOT : A : ' + data.A + '</td></tr>';
                            });
                        }
                    } else if (msg_type === 2) {
                        var stringUrl = msg_url.replace(/[0-9]/g, '');
                        var msgNew = stringUrl;
                        if (stringUrl.length > 15) {
                            msgNew = stringUrl.substring(0, 15) + '...';
                        }
                        if (agent_id === null) {
                            messageWithSender = this.context.currentVisitor.client_short + ' : <a href="' + encodeURIComponent(msg) + '">' + msgNew + '</a>';
                        } else {
                            messageWithSender = agent_name + ' : <a href="' + encodeURIComponent(msg) + '">' + msgNew + '</a>';
                        }
                        finalChatData += '<tr><td>(' + time + ')</td><td>' + messageWithSender + '</td></tr>';
                    } else if (msg_type === 3 || msg_type === 4) {
                        var stringUrl2 = msg_url.replace(/[0-9]/g, '');
                        var msgNew2 = stringUrl2;
                        if (stringUrl2.length > 15) {
                            msgNew2 = stringUrl2.substring(0, 15) + '...';
                        }

                        var msg1 = '<a href="' + encodeURIComponent(msg) + '" src="' + encodeURIComponent(msg) + '" download="' + msg_url + '" target="_blank">' + msgNew2 + '</a>';

                        if (agent_id === null) {
                            messageWithSender = this.context.currentVisitor.client_short + ' : ' + msg1;
                        } else {
                            messageWithSender = agent_name + ' : ' + msg1;
                        }
                        finalChatData += '<tr><td>(' + time + ')</td><td>' + messageWithSender + '</td></tr>';
                    } else {
                        var tmpMsg = msg_type === 12 ? removeHtmlTags(msg) : msg;
                        var filterMsg = String(tmpMsg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
                        if (agent_id === null) {
                            messageWithSender = this.context.currentVisitor.client_short + ' : ' + encodeURIComponent(filterMsg);
                        } else {
                            messageWithSender = agent_name + ' : ' + encodeURIComponent(filterMsg);
                        }
                        finalChatData += '<tr><td>(' + time + ')</td><td>' + messageWithSender + '</td></tr>';
                    }
                }
                finalChatData += "</table>";
                var body = [`email=${email.toLowerCase()}&&type=send_transcript&&tabledata=${finalChatData}`];
                fetch(GATEWAY_URL + '/sendEmail', {
                    method: "post",
                    body: body,
                    headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
                })
                    .then(response => {
                        if (response.status === 200 && response.ok === true) {
                            toast.success("Request accepted, with in 5-10 minutes you will receive transcript mail");
                        } else {
                            toast.error("ERROR in sending mail");
                        }
                    })
                    .catch(e => {
                        console.log(e, "ERROR");
                    });
            }
        });
    }
    /* Send Email transcript to current visitor End */

    viewVisitorPaths = () => {
        var myArr = [{ key: "isCloseBtnClicked", value: "" }, { key: "rightSectionItemToShow", value: "visitorPath" }];
        this.context.updateGlobalData("array", myArr);
    }

    showStickyNotes = () => {
        var myArr = [{ key: "isCloseBtnClicked", value: "" }, { key: "rightSectionItemToShow", value: "notesList" }];
        this.context.updateGlobalData("array", myArr);
    }

    showTicketing = () => {
        var myArr = [{ key: "isCloseBtnClicked", value: "" }, { key: "rightSectionItemToShow", value: "ticketing" }];
        this.context.updateGlobalData("array", myArr);
    }

    /* Edit client name and email start */

    changeText = (fieldName) => {
        if (fieldName === 'client_name') {
            this.setState({ isEditClientName: true });
        } else if (fieldName === 'client_email') {
            this.setState({ isEditClientEmail: true });
        } else if (fieldName === "client_company_name") {
            this.setState({ isEditClientCompany: true });
        }
    }

    changeClientName = (e) => {
        this.setState({ changeClientName: e.target.value });
    }

    changeCompanyName = (e) => {
        this.setState({ changeCompanyName: e.target.value });
    }

    changeClientEmail = (e) => {
        this.setState({ changeClientEmail: e.target.value });
    }

    updateVisitor = (fieldName) => {
        console.log("called-----")
        if (fieldName === 'client_name') {
            if ((this.state.changeClientName !== "") && (this.state.changeClientName !== this.context.currentVisitor.client_name)) {
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.context.liveChatDocId).update({ client_name: this.state.changeClientName });
                var client_name = this.state.changeClientName;
                var client_short = nameIntials(client_name);
                var client_short_name = client_name;
                console.log("hii client", client_name, client_short_name)
                if (client_short_name.length > 20) {
                    client_short_name = client_short_name.substring(0, 20) + '...';
                }
                let visitorObj = this.context.currentVisitor;
                visitorObj.client_name = client_name;
                visitorObj.client_short = client_short;
                visitorObj.client_short_name = client_short_name;
                this.context.updateGlobalData("currentVisitor", visitorObj);
            }
            this.setState({ changeClientName: "", isEditClientName: false });
        } else if (fieldName === 'client_email') {
            if ((this.state.changeClientEmail !== "") && (this.state.changeClientEmail !== this.context.currentVisitor.client_email)) {
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.context.liveChatDocId).update({ client_email: this.state.changeClientEmail });
                let visitorObj = this.context.currentVisitor;
                visitorObj.client_email = this.state.changeClientEmail;
                this.context.updateGlobalData("currentVisitor", visitorObj);
            }
            this.setState({ changeClientEmail: "", isEditClientEmail: false });
        } else if (fieldName === 'client_company_name') {
            if ((this.state.changeCompanyName !== "") && (this.state.changeCompanyName !== this.context.currentVisitor.client_company_name)) {
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.context.liveChatDocId).update({ client_company_name: this.state.changeCompanyName });
                var client_company_name = this.state.changeCompanyName;
                var client_short2 = nameIntials(client_company_name);
                var client_short_name2 = client_short2;
                if (client_short_name2.length > 20) {
                    client_short_name2 = client_short_name2.substring(0, 20) + '...';
                }
                let visitorObj = this.context.currentVisitor;
                visitorObj.client_company_name = client_company_name;
                visitorObj.client_short = client_short2;
                visitorObj.client_short_name = client_short_name2;
                this.context.updateGlobalData("currentVisitor", visitorObj);
            }
            this.setState({ changeCompanyName: "", isEditClientCompany: false });
        }
    }


    leftListing = () => {
        var leftListAdd = document.getElementById('SideBarIO');
        leftListAdd.classList.add('IOLP')
    }
    addagentdiv = () => {
        var addagentdivShow = document.getElementById('chat1-members');
        addagentdivShow.classList.add('addagentdivCss')
    }

    /* Edit client name and email End */

    copytoclipboard() {
        var elm = document.getElementById("visitor_currentPath");
        // for Internet Explorer
        var range;
        if (document.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(elm);
            range.select();
            document.execCommand("Copy");
            toast.success('Copied!');
            return false;
        } else if (window.getSelection) {
            // other browsers
            var selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(elm);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("Copy");
            toast.success('Copied!');
        }
    }


    endGuideTour = (docId, context) => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails === false) {
            return;
        }
        firebaseServices.db.collection("users").doc(userDetails.ownerId).collection("onboarding_guide").doc(docId).update({
            status: true
        })
        this.context.updateGlobalData(context, true);
    }

    guidenextStep = (docId, step, context) => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails === false) {
            return;
        }
        firebaseServices.db.collection("users").doc(userDetails.ownerId).collection("onboarding_guide").doc(docId).update({
            step: step,
        })
        this.context.updateGlobalData(context, step);
    }

    /* Zoom functions start */

    openMeetingForm = () => {
        this.context.updateGlobalData("showMeetingForm", true);
    }

    /* Cobrowsing functions start */
    startCoBrowsing = () => {
        if (this.state.isCoBrowsingAvailable && this.state.cobrowsingdevicesConnected.length <= this.state.totalCobrowsingLicense) {
            this.setState({ loader: true });
            fetch(MAIL_FUNCTION_URL + `/cobrowsing/cobrowsing?companyId=${this.agent.ownerId}&chatId=${this.context.liveChatDocId}`, {
                method: 'GET',
            }).then(response => response.json())
                .then(result => {
                    const token = result.token
                    cobrowse.token = token;
                    window.cobrowse = cobrowse

                    return firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).get().then(async (doc) => {
                        if (doc.exists) {
                            let deviceID = doc.data().coBrowsingData.coBrowseDeviceID.id;
                            console.log("\n\n\n💡🛠 -> file: header.js -> line 641 -> deviceID", deviceID);
                            // list devices to use
                            this.setState({ loader: false });
                            return await this.refreshCobrowsing(deviceID);
                        }
                    }).catch(err => {
                        console.log(err);
                        this.setState({ loader: false });
                    });
                })
                .catch(error => {
                    console.log('error', error);
                    this.setState({ loader: false });
                });
        } else {
            toast.error("All cobrowsing sessions are already in use.");
        }
    }

    refreshCobrowsing = async (deviceID = '') => {
        if (deviceID === '') {
            const [devices, sessions] = await Promise.all([
                cobrowse.devices.list(),
                cobrowse.sessions.list({ state: ['active'], activated: new Date(0), agent: 'all' })
            ]);
            // subscribe to updates for these resources & sessions
            devices.forEach(device => device.subscribe());
            sessions.forEach(sessions => sessions.subscribe());
            // check the current state for any updates
            devices.forEach(device => device.on('updated', () => { console.log('.....Cobrowsing Device info updated......') }));
            sessions.forEach(session => session.on('updated', () => { console.log('.....Cobrowsing session info updated......') }));
            return { device: devices, sessions: sessions };
        } else {
            const [device, sessions] = await Promise.all([
                cobrowse.devices.get(deviceID),
                cobrowse.sessions.list({ state: ['active'], activated: new Date(0), agent: 'all' })
            ]);
            // subscribe to updates for these resources & sessions
            device.subscribe();
            sessions.forEach(sessions => sessions.subscribe());
            // check the current state for any updates
            device.on('updated', () => { console.log('.....Cobrowsing Device info updated......') });
            sessions.forEach(session => session.on('updated', () => { console.log('.....Cobrowsing session info updated......') }));
            return await this.watchCobrowser(window.open(`${cobrowse.api}/connect/device/${device.id}?token=${cobrowse.token}&end_action=none`), deviceID);
        }
    }

    updateDeviceConnected = (devicesConnected, deviceID, operation) => {
        try {
            if (operation === 'remove') {
                let index = devicesConnected.indexOf(deviceID);
                if (index > -1) { // only splice array when item is found
                    devicesConnected.splice(index, 1); // 2nd parameter means remove one item only
                }
                return devicesConnected;
            } else {
                let index = devicesConnected.indexOf(deviceID);
                if (index === -1) { // only push array when item is not found
                    devicesConnected.push(deviceID);
                }
                return devicesConnected;
            }
        } catch (err) { }
    }

    watchCobrowser = async (win, deviceID) => {
        const ctx = await cobrowse.attachContext(win);
        let sessionEndFlag = false;
        ctx.on('session.updated', (session) => {
            console.log('running.. -----session.state----->', session.state);
            if (session.ended) {
                win.close();
                sessionEndFlag = true;
                let resources = this.refreshCobrowsing();
                ctx.destroy();
                firebaseServices.db.collection('cobrowsing').doc('status').get().then((docCobrowsing) => {
                    if (docCobrowsing.exists) {
                        let devicesConnected = this.updateDeviceConnected(docCobrowsing.data().devicesConnected, deviceID, 'remove');
                        this.setState({ isCoBrowsingAvailable: true, loader: false, cobrowsingdevicesConnected: devicesConnected });
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).update({ isStartingCobrowsing: false });
                        return firebaseServices.db.collection('cobrowsing').doc('status').update({
                            'isCoBrowsingAvailable': true,
                            'devicesConnected': devicesConnected
                        }).catch(err => console.log('error while updating cobrowsing status', err));
                    } else {
                        this.setState({ isCoBrowsingAvailable: true, loader: false, cobrowsingdevicesConnected: [] });
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).update({ isStartingCobrowsing: false });
                        return firebaseServices.db.collection('cobrowsing').doc('status').set({
                            'isCoBrowsingAvailable': true,
                            'devicesConnected': []
                        }).catch(err => console.log('error while setting cobrowsing status', err));
                    }
                }).catch(err => console.log('error while getting cobrowsing status', err));
            } else if (session.activated) {
                firebaseServices.db.collection('cobrowsing').doc('status').get().then((docCobrowsing) => {
                    if (docCobrowsing.exists) {
                        let devicesConnected = this.updateDeviceConnected(docCobrowsing.data().devicesConnected, deviceID, 'add');
                        if (devicesConnected.length >= this.state.totalCobrowsingLicense) {
                            this.setState({ isCoBrowsingAvailable: false, loader: false, cobrowsingdevicesConnected: devicesConnected });
                            return firebaseServices.db.collection('cobrowsing').doc('status').update({
                                'isCoBrowsingAvailable': false,
                                'devicesConnected': devicesConnected
                            }).catch(err => console.log('error while updating cobrowsing status', err));
                        }
                        else {
                            this.setState({ isCoBrowsingAvailable: true, loader: false, cobrowsingdevicesConnected: devicesConnected });
                            return firebaseServices.db.collection('cobrowsing').doc('status').update({
                                'isCoBrowsingAvailable': true,
                                'devicesConnected': devicesConnected
                            }).catch(err => console.log('error while updating cobrowsing status', err));
                        }
                    } else {
                        if (1 === this.state.totalCobrowsingLicense) {
                            this.setState({ isCoBrowsingAvailable: false, loader: false, cobrowsingdevicesConnected: [deviceID] });
                            return firebaseServices.db.collection('cobrowsing').doc('status').set({
                                'isCoBrowsingAvailable': false,
                                'devicesConnected': [deviceID]
                            }).catch(err => console.log('error while setting cobrowsing status', err));
                        }
                        else {
                            this.setState({ isCoBrowsingAvailable: true, loader: false, cobrowsingdevicesConnected: [deviceID] });
                            return firebaseServices.db.collection('cobrowsing').doc('status').update({
                                'isCoBrowsingAvailable': true,
                                'devicesConnected': [deviceID]
                            }).catch(err => console.log('error while updating cobrowsing status', err));
                        }
                    }
                }).catch(err => console.log('error while getting cobrowsing status', err));
            } else {
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).update({ isStartingCobrowsing: true });
            }
        })

        // to check window of cobrowsing closed every 5 sec
        let stopCheck = setInterval(() => {
            if (win.closed && !sessionEndFlag) {
                console.log('---------cobrowsing window closed-----------');
                console.log('ending session');
                ctx.endSession();
                ctx.destroy();
                firebaseServices.db.collection('cobrowsing').doc('status').get().then((docCobrowsing) => {
                    if (docCobrowsing.exists) {
                        let devicesConnected = this.updateDeviceConnected(docCobrowsing.data().devicesConnected, deviceID, 'remove');
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).update({ isStartingCobrowsing: false });
                        this.setState({ isCoBrowsingAvailable: true, loader: false, cobrowsingdevicesConnected: devicesConnected });
                        return firebaseServices.db.collection('cobrowsing').doc('status').update({
                            'isCoBrowsingAvailable': true,
                            'devicesConnected': devicesConnected
                        }).catch(err => console.log('error while updating cobrowsing status', err));
                    } else {
                        this.setState({ isCoBrowsingAvailable: true, loader: false, cobrowsingdevicesConnected: [] });
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).update({ isStartingCobrowsing: false });
                        return firebaseServices.db.collection('cobrowsing').doc('status').set({
                            'isCoBrowsingAvailable': true,
                            'devicesConnected': []
                        }).catch(err => console.log('error while setting cobrowsing status', err));
                    }
                }).catch(err => console.log('error while getting cobrowsing status', err));
                clearInterval(stopCheck);
            }
        }, 5000);

        // maximum cobrowsing allowed time
        setTimeout(() => {
            if (!sessionEndFlag) {
                console.log('ending session');
                ctx.endSession();
                ctx.destroy();
                firebaseServices.db.collection('cobrowsing').doc('status').get().then((docCobrowsing) => {
                    if (docCobrowsing.exists) {
                        let devicesConnected = this.updateDeviceConnected(docCobrowsing.data().devicesConnected, deviceID, 'remove');
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).update({ isStartingCobrowsing: false });
                        this.setState({ isCoBrowsingAvailable: true, loader: false, cobrowsingdevicesConnected: devicesConnected });
                        return firebaseServices.db.collection('cobrowsing').doc('status').update({
                            'isCoBrowsingAvailable': true,
                            'devicesConnected': devicesConnected
                        }).catch(err => console.log('error while updating cobrowsing status', err));
                    } else {
                        this.setState({ isCoBrowsingAvailable: true, loader: false, cobrowsingdevicesConnected: [] });
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).update({ isStartingCobrowsing: false });
                        return firebaseServices.db.collection('cobrowsing').doc('status').set({
                            'isCoBrowsingAvailable': true,
                            'devicesConnected': []
                        }).catch(err => console.log('error while setting cobrowsing status', err));
                    }
                }).catch(err => console.log('error while getting cobrowsing status', err));
            }
        }, MAX_COBROWSING_TIME)

    }

    render() {
        console.log("this.context.showMidHeader -     11111112222", this.context.showMidHeader)
        // if(document.getElementById('calling_incoming') && document.getElementById('calling_incoming').style.display === "block"){
        //     if(!this.state.microphoneAccess){
        //         this.setState({showMicrophoneAccessModal: true})
        //         // document.getElementById('calling_incoming').style.display = "none"
        //     }
        // }
        if (!this.context.showMidHeader) {
            return false;
        }

        let chatPickStatusTransaction = this.context.chatPickStatusTransaction;
        let showTransfer = this.context.currentVisitor ? this.context.currentVisitor.agent_ids.length : 1;

        let visitorEmail;
        if (!validateEmail(this.context.currentVisitor.client_email)) {
            visitorEmail = ENTER_EMAIL;
        } else {
            visitorEmail = this.context.currentVisitor.client_email;
        }

        var disab = "";
        let tmpData = this.context.chatTranslator ? this.context.chatTranslator : {};
        if (tmpData.hasOwnProperty(this.context.liveChatDocId)) {
            if (tmpData[this.context.liveChatDocId].status) {
                disab = "none";
            }
        }

        var allAgents = [];
        if (chatPickStatusTransaction.status === "serving") {
            let allChatAgents = this.context.currentVisitor.agents ? this.context.currentVisitor.agents : {};
            if (Object.keys(allChatAgents).length > 0) {
                for (const key in allChatAgents) {
                    let agent = allChatAgents[key];
                    allAgents.push({
                        id: key,
                        agent_short: agent.agentShort,
                        alias: agent.alias,
                        email: agent.email,
                        name: agent.name,
                        profile_img: agent.profileImg ? agent.profileImg : ""
                    });
                }
            }
        }

        var isPhoneCall = (this.context.currentVisitor && CHAT_TYPES.indexOf(this.context.currentVisitor.start_url) !== -1) ? true : false;
        var isVoipCall = this.context.currentVisitor.start_url === "voip_call" ? true : false;
        var fromVisitor = this.context.currentVisitor.fromVisitor ? this.context.currentVisitor.fromVisitor : "";
        var iswhatsappchat = this.context.currentVisitor.start_url === "whatsapp" ? true : false;
        var showVoipCallBtn = false;
        if (!isPhoneCall && (this.canCalling === "voip" || this.canCalling === "phone_voip") && !iswhatsappchat) {
            showVoipCallBtn = true;
        }

        var showPhoneCallBtn = false;
        if (this.context.currentVisitor.client_phone && (this.canCalling === "phone" || this.canCalling === "phone_voip")) {
            if (!isVoipCall) {
                showPhoneCallBtn = true;
            }
        }

        var isWhatsAppCall = this.context.currentVisitor.start_url === "incoming_whatsapp" ? true : false;
        var isFBChat = this.context.currentVisitor.start_url === "incoming_fb" ? true : false;
        var isInstaChat = this.context.currentVisitor.start_url === "incoming_insta" ? true : false;
        var isTDMChat = this.context.currentVisitor.start_url === "incoming_tdm" ? true : false;
        var isSM = ["Telegram", "Facebook","metaFB"].includes(this.context.currentVisitor.current_pageUrl)  //for checking if chat is initiated from any social media 
        var mobileUpper = ""
        if (this.context.isMobileDevice) {
            mobileUpper = "mobile-upper"
        }


        return (
            <>
                {this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}
                <ReactTooltip place={'bottom'} effect={'solid'} multiline={true} />
                <div style={{ display: "none" }} id="chatMessageLoader" className="ong-inc-chat-ended loader">
                    <div className="ong-inc-chat">
                        {/*<p className="small text-screen1">Loading....</p> */}
                        <div className="loader" key={0}>
                            <div className="spinner">
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chat-header border-bottom py-2 py-lg-3 px-lg-4">
                    <div className="container-xxl px-2">
                        <div className="row align-items-center">
                            <div className="col-1 pr-0 d-xl-none d-none">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item" onClick={this.leftListing}>
                                        <span className="text-muted px-0" data-chat="open">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 15.58 28.922">
                                                <g id="back" transform="translate(-102.298 0)">
                                                    <g id="Group_12812" data-name="Group 12812" transform="translate(102.298 0)">
                                                        <path id="Path_2515" data-name="Path 2515" d="M104.984,14.468,117.552,1.9A1.113,1.113,0,1,0,115.978.326L102.623,13.681a1.113,1.113,0,0,0,0,1.574L115.978,28.61a1.113,1.113,0,0,0,1.574-1.574Z" transform="translate(-102.298 0)" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-8 col-xl-6">
                                <div className="media text-center text-xl-left">
                                    <div className={this.context.currentVisitor.bg_color ? "avatar avatar-sm d-xl-inline-block mr-4 mt-3 " + this.context.currentVisitor.bg_color : "avatar avatar-sm d-xl-inline-block mr-4 mt-3 "}>
                                        <span>{this.context.currentVisitor.client_short}</span>
                                    </div>
                                    <div className="media-body align-self-center text-truncate chat-header text-left">
                                        <h6 className="text-truncate mb-n2 k-flex header-text-width">
                                            {(this.context.currentVisitor.client_company_name && (this.context.currentVisitor.client_name === "" || this.context.currentVisitor.client_name.indexOf("#Visitor") !== -1)) ? (
                                                <div className="premium pakage-icon">&nbsp;</div>
                                            ) : (null)}
                                            {(this.context.currentVisitor.client_company_name && (this.context.currentVisitor.client_name === "" || this.context.currentVisitor.client_name.indexOf("#Visitor") !== -1)) ? (<>
                                                {this.state.isEditClientCompany ? (<input type="text" className="chat-edit-name" defaultValue={this.context.currentVisitor.client_company_name} autoFocus onChange={(evt) => { this.changeCompanyName(evt) }} onBlur={() => this.updateVisitor('client_company_name')} size="15" />) : (
                                                    <span onClick={() => this.changeText('client_company_name')} className="name-chat-header userCountryLocation newprofileInformationDetails" title={this.context.currentVisitor.client_company_name}>{this.context.currentVisitor.client_company_name}</span>)}
                                                <span onClick={() => this.changeText('client_company_name')} className="edit-icon-name cursorPointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.141" height="15.064" viewBox="0 0 15.141 15.064">
                                                        <g id="edit-black" transform="translate(0 -0.001)">
                                                            <path id="Path_942" data-name="Path 942" d="M13.975,47.159a.377.377,0,0,0-.377.377v3.348a1.132,1.132,0,0,1-1.131,1.131H1.886A1.132,1.132,0,0,1,.754,50.884V41.057a1.133,1.133,0,0,1,1.131-1.131H5.234a.377.377,0,1,0,0-.754H1.886A1.888,1.888,0,0,0,0,41.057v9.827A1.888,1.888,0,0,0,1.886,52.77H12.467a1.888,1.888,0,0,0,1.886-1.886V47.536a.377.377,0,0,0-.378-.377Zm0,0" transform="translate(0 -37.705)" fill="#fff" />
                                                            <path id="Path_943" data-name="Path 943" d="M118.313.758a1.7,1.7,0,0,0-2.4,0l-6.728,6.728a.377.377,0,0,0-.1.166l-.885,3.194a.377.377,0,0,0,.464.464l3.194-.885a.377.377,0,0,0,.166-.1L118.755,3.6a1.7,1.7,0,0,0,0-2.4Zm-8.306,6.973,5.506-5.506L117.289,4l-5.506,5.506Zm-.355.712,1.419,1.419-1.962.544Zm8.57-5.375-.4.4-1.776-1.776.4-.4a.943.943,0,0,1,1.333,0l.443.443a.944.944,0,0,1,0,1.332Zm0,0" transform="translate(-104.11 -0.261)" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </>) : (<>
                                                {this.state.isEditClientName ? (<input type="text" className="chat-edit-name" defaultValue={this.context.currentVisitor.client_name} autoFocus onChange={(evt) => { this.changeClientName(evt) }} onBlur={() => this.updateVisitor('client_name')} size="15" />) : (<>
                                                    {(fromVisitor === "temp_send_chat_window" || fromVisitor === "temp_send_sms_window" || isVoipCall) ? (<span className="name-chat-header userCountryLocation newprofileInformationDetails" title={this.context.currentVisitor.client_name}> {this.context.currentVisitor.client_short_name}</span>) : (<span onClick={() => this.changeText('client_name')} className="name-chat-header userCountryLocation newprofileInformationDetails" title={this.context.currentVisitor.client_name}>   {this.context.currentVisitor.client_short_name}</span>)}
                                                </>)}
                                                {(fromVisitor === "temp_send_chat_window" || fromVisitor === "temp_send_sms_window" || isVoipCall) ? (null) : (<span onClick={() => this.changeText('client_name')} className="edit-icon-name cursorPointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.141" height="15.064" viewBox="0 0 15.141 15.064">
                                                        <g id="edit-black" transform="translate(0 -0.001)">
                                                            <path id="Path_942" data-name="Path 942" d="M13.975,47.159a.377.377,0,0,0-.377.377v3.348a1.132,1.132,0,0,1-1.131,1.131H1.886A1.132,1.132,0,0,1,.754,50.884V41.057a1.133,1.133,0,0,1,1.131-1.131H5.234a.377.377,0,1,0,0-.754H1.886A1.888,1.888,0,0,0,0,41.057v9.827A1.888,1.888,0,0,0,1.886,52.77H12.467a1.888,1.888,0,0,0,1.886-1.886V47.536a.377.377,0,0,0-.378-.377Zm0,0" transform="translate(0 -37.705)" fill="#fff" />
                                                            <path id="Path_943" data-name="Path 943" d="M118.313.758a1.7,1.7,0,0,0-2.4,0l-6.728,6.728a.377.377,0,0,0-.1.166l-.885,3.194a.377.377,0,0,0,.464.464l3.194-.885a.377.377,0,0,0,.166-.1L118.755,3.6a1.7,1.7,0,0,0,0-2.4Zm-8.306,6.973,5.506-5.506L117.289,4l-5.506,5.506Zm-.355.712,1.419,1.419-1.962.544Zm8.57-5.375-.4.4-1.776-1.776.4-.4a.943.943,0,0,1,1.333,0l.443.443a.944.944,0,0,1,0,1.332Zm0,0" transform="translate(-104.11 -0.261)" />
                                                        </g>
                                                    </svg>
                                                </span>)}
                                            </>)}

                                            {(isPhoneCall || isVoipCall || iswhatsappchat) ? (null) : (<ul className="maps-all pl-2 header-flag-safari liveChatHeadreTootltip">
                                                <li className={"flag-icon-" + this.context.midHeadercountry.toLowerCase().replace(/ /gi, "-") + " flag all-flas"}>
                                                    <span className="titleShowHover"><h6><strong>Location:</strong> {(typeof (this.context.currentVisitor.geoDetails.country_name) !== 'undefined') ? this.context.currentVisitor.geoDetails.country_name : ""}</h6> <h6><strong>IP:</strong> {(typeof this.context.currentVisitor.geoDetails.country_ip !== 'undefined') ? this.context.currentVisitor.geoDetails.country_ip : ""}</h6></span>
                                                </li><li className={this.context.midHeaderoperating.toString().toLowerCase().replace(" ", "-") + " os all-flas"}>
                                                    <span className="titleShowHover"><h6><strong>OS:</strong> {this.context.midHeaderoperating} </h6></span>
                                                </li>
                                                <li className={this.context.midHeaderbrowser.toLowerCase().replace(" ", "-") + " browser all-flas"}>
                                                    <span className="titleShowHover"><h6><strong>Browser:</strong> {this.context.midHeaderbrowser} </h6></span>
                                                </li></ul>)}
                                        </h6>
                                        {this.state.isEditClientEmail ? (
                                            <input className="chat-edit-email" type="text" defaultValue={visitorEmail} autoFocus onChange={(evt) => { this.changeClientEmail(evt) }} onBlur={() => this.updateVisitor('client_email')} size="20" />
                                        ) : (<>
                                            {(fromVisitor === "temp_send_chat_window" || fromVisitor === "temp_send_sms_window" || isVoipCall) ? (<small className="text-black dblock header-path">{visitorEmail}</small>) : (<small onClick={() => this.changeText('client_email')} className="text-black dblock header-path">{visitorEmail}<span onClick={() => this.changeText('client_email')} className="edit-icon-name cursorPointer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.141" height="15.064" viewBox="0 0 15.141 15.064">
                                                    <g id="edit-black" transform="translate(0 -0.001)">
                                                        <path id="Path_942" data-name="Path 942" d="M13.975,47.159a.377.377,0,0,0-.377.377v3.348a1.132,1.132,0,0,1-1.131,1.131H1.886A1.132,1.132,0,0,1,.754,50.884V41.057a1.133,1.133,0,0,1,1.131-1.131H5.234a.377.377,0,1,0,0-.754H1.886A1.888,1.888,0,0,0,0,41.057v9.827A1.888,1.888,0,0,0,1.886,52.77H12.467a1.888,1.888,0,0,0,1.886-1.886V47.536a.377.377,0,0,0-.378-.377Zm0,0" transform="translate(0 -37.705)" fill="#fff" />
                                                        <path id="Path_943" data-name="Path 943" d="M118.313.758a1.7,1.7,0,0,0-2.4,0l-6.728,6.728a.377.377,0,0,0-.1.166l-.885,3.194a.377.377,0,0,0,.464.464l3.194-.885a.377.377,0,0,0,.166-.1L118.755,3.6a1.7,1.7,0,0,0,0-2.4Zm-8.306,6.973,5.506-5.506L117.289,4l-5.506,5.506Zm-.355.712,1.419,1.419-1.962.544Zm8.57-5.375-.4.4-1.776-1.776.4-.4a.943.943,0,0,1,1.333,0l.443.443a.944.944,0,0,1,0,1.332Zm0,0" transform="translate(-104.11 -0.261)" />
                                                    </g>
                                                </svg>
                                            </span>
                                            </small>)}

                                        </>)}
                                        {(isPhoneCall || isVoipCall) ? (<span className="text-truncate k-flex header-text-width">
                                            <small id="visitor_currentPath" className="text-black dblock header-path text-truncate">&nbsp;</small>
                                            <span className="edit-icon-name cursorPointer position-relative" style={{ top: '-5px' }} >&nbsp;</span>
                                        </span>) : (<span className="text-truncate k-flex header-text-width">
                                            <small id="visitor_currentPath" className="text-black dblock header-path text-truncate">{this.context.currentVisitor.current_pageUrl}</small>
                                            <span className="edit-icon-name cursorPointer position-relative" style={{ top: '-5px' }} onClick={() => this.copytoclipboard()} data-tip="Copy code" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17.637" height="15.421" viewBox="0 0 17.637 20.421"><path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M7.641,1.5H18.78a1.862,1.862,0,0,1,1.856,1.856v13H18.78v-13H7.641ZM4.856,5.213H15.067a1.862,1.862,0,0,1,1.856,1.856v13a1.862,1.862,0,0,1-1.856,1.856H4.856A1.862,1.862,0,0,1,3,20.065v-13A1.862,1.862,0,0,1,4.856,5.213Zm0,14.852H15.067v-13H4.856Z" transform="translate(-3 -1.5)" fill="#1b85fc" /></svg>
                                            </span>
                                        </span>)}
                                    </div>
                                </div>
                            </div>

                            {this.state.showMicrophoneAccessModal ?
                                <div class="session-popup call-allow">
                                    <div class="session-expire">
                                        <img className='crossIcon' src={require('../../../../assets/img/cross.png')} onClick={()=>{
                                            this.setState({showMicrophoneAccessModal:false})
                                        }}/>
                                        <img src={require('../../../../assets/img/call-allow-pop.png')} />
                                        <h3>Allow Microphone Permission</h3>
                                        <p>You can still turn off your microphone anytime on the call</p>
                                    </div>
                                </div> : null}

                            {!chatPickStatusTransaction ? (null) : (<>
                                {chatPickStatusTransaction.status === "chatting" ? (<div className="col-4 col-xl-6 text-right pl-0">
                                    <ul className={this.context.incoming_guide === false && this.context.incoming_guide_step === 2 ? "nav justify-content-end align-items-center position-top" : "nav justify-content-end align-items-center"}>
                                        {this.context.incoming_guide === false && this.context.incoming_guide_step === 2 ? (<div className="overlay-guide bottom-div"></div>) : (null)}
                                        {((isWhatsAppCall || isFBChat || isTDMChat || isInstaChat) && !this.context.isMobileDevice) ?
                                            (<li id="headerbtn-addagent" className="nav-item list-inline-item d-none d-xl-block position-relative 3">
                                                <span data-tip="Add Agent" onClick={() => this.showAvailableAgents()} className="nav-link text-muted px-3">
                                                    <img alt="" src={require("../../../../assets/images/livechat/add-user.svg")} />
                                                </span>
                                                {this.state.groupChatAgents.length > 1 ? (
                                                    <ul className="user-showings">
                                                        {this.state.groupChatAgents.map((agent, index) =>
                                                            <li key={index}>{agent.profile_img ? (<img alt="" src={agent.profile_img} />) : agent.agent_short}
                                                                <div className="user-hover-show">
                                                                    <div className="avatar">
                                                                        <span>{agent.agent_short}</span>
                                                                    </div>
                                                                    <h6 className="text-name-hading name-chat-header">{agent.name}</h6>
                                                                </div>
                                                            </li>)}
                                                    </ul>
                                                ) : (null)}
                                            </li>)
                                            : (null)}
                                        {(isPhoneCall || isVoipCall || this.context.isMobileDevice) || isSM ? (null) : (<li id="headerbtn-addagent" className="nav-item list-inline-item d-none d-xl-block position-relative 3">
                                            <span data-tip="Add Agent" onClick={() => this.showAvailableAgents()} className="nav-link text-muted px-3">
                                                <img alt="" src={require("../../../../assets/images/livechat/add-user.svg")} />
                                            </span>
                                            {this.state.groupChatAgents.length > 1 ? (
                                                <ul className="user-showings">
                                                    {this.state.groupChatAgents.map((agent, index) =>
                                                        <li key={index}>{agent.profile_img ? (<img alt="" src={agent.profile_img} />) : agent.agent_short}
                                                            <div className="user-hover-show">
                                                                <div className="avatar">
                                                                    <span>{agent.agent_short}</span>
                                                                </div>
                                                                <h6 className="text-name-hading name-chat-header">{agent.name}</h6>
                                                            </div>
                                                        </li>)}
                                                </ul>
                                            ) : (null)}
                                        </li>)}
                                        {(!this.context.isMobileDevice && this.agent.whatsapp_msg && this.context.currentVisitor.client_phone && this.state.clientPlan && this.state.clientPlan.status === "data") ? (<li className="nav-item list-inline-item">
                                            <span onClick={this.showWhatsAppTemplates} className="nav-item text-muted cursor-pointer">
                                                <svg id="whatsapp" xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 35.547 35.547">
                                                    <path id="Path_8741" data-name="Path 8741" d="M17.778,0h-.009A17.762,17.762,0,0,0,3.384,28.191l-2.215,6.6L8,32.61A17.77,17.77,0,1,0,17.778,0Z" fill="#4caf50" />
                                                    <path id="Path_8742" data-name="Path 8742" d="M127.937,134.057c-.429,1.211-2.131,2.215-3.488,2.508-.929.2-2.142.355-6.225-1.337-5.223-2.164-8.587-7.472-8.849-7.816a10.151,10.151,0,0,1-2.111-5.361,5.674,5.674,0,0,1,1.817-4.326,2.582,2.582,0,0,1,1.817-.638c.22,0,.418.011.6.02.522.022.784.053,1.129.878.429,1.033,1.473,3.584,1.6,3.846a1.058,1.058,0,0,1,.076.962,3.071,3.071,0,0,1-.575.815c-.262.3-.511.533-.773.858-.24.282-.511.584-.209,1.106a15.775,15.775,0,0,0,2.884,3.584,13.067,13.067,0,0,0,4.168,2.57,1.124,1.124,0,0,0,1.253-.2,21.5,21.5,0,0,0,1.389-1.84.992.992,0,0,1,1.275-.387c.48.167,3.019,1.422,3.541,1.682s.866.387.993.607A4.426,4.426,0,0,1,127.937,134.057Z" transform="translate(-99.817 -108.959)" fill="#fafafa" />
                                                </svg>
                                            </span>
                                        </li>) : (null)}
                                        {(!this.context.isMobileDevice && this.context.currentVisitor.id.indexOf("TEMP") === -1 && (this.state.groupChatAgents.length === 1 || this.context.currentVisitor.bg_color === "agent-voip-call") && (showVoipCallBtn || showPhoneCallBtn)) && !isSM ? (<><li id="button-call" className="nav-item list-inline-item d-none d-xl-block" onMouseEnter={() => { ReactTooltip.show(this.refCallButton) }} onMouseLeave={() => { ReactTooltip.hide(this.refCallButton) }}>
                                            <span className="nav-link text-muted px-3" data-tip="Start Call" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ref={ref => this.refCallButton = ref}>
                                                <svg id="telephone" xmlns="http://www.w3.org/2000/svg" width="12.996" height="12.996" viewBox="0 0 13.996 13.996">
                                                    <g id="Group_2755" data-name="Group 2755" transform="translate(6.998 3.732)">
                                                        <g id="Group_2754" data-name="Group 2754">
                                                            <path id="Path_2242" data-name="Path 2242" d="M240.467,128a.467.467,0,1,0,0,.933,1.868,1.868,0,0,1,1.866,1.866.467.467,0,0,0,.933,0A2.8,2.8,0,0,0,240.467,128Z" transform="translate(-240 -128)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_2757" data-name="Group 2757" transform="translate(6.998 1.866)">
                                                        <g id="Group_2756" data-name="Group 2756">
                                                            <path id="Path_2243" data-name="Path 2243" d="M240.467,64a.467.467,0,0,0,0,.933,3.736,3.736,0,0,1,3.732,3.732.467.467,0,1,0,.933,0A4.671,4.671,0,0,0,240.467,64Z" transform="translate(-240 -64)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_2759" data-name="Group 2759" transform="translate(6.998)">
                                                        <g id="Group_2758" data-name="Group 2758">
                                                            <path id="Path_2244" data-name="Path 2244" d="M240.467,0a.467.467,0,1,0,0,.933,5.6,5.6,0,0,1,5.6,5.6.467.467,0,1,0,.933,0A6.539,6.539,0,0,0,240.467,0Z" transform="translate(-240 0)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_2761" data-name="Group 2761" transform="translate(0 0.933)">
                                                        <g id="Group_2760" data-name="Group 2760">
                                                            <path id="Path_2245" data-name="Path 2245" d="M12.6,41.331a8.66,8.66,0,0,1-3.835-.876.458.458,0,0,0-.357-.021.471.471,0,0,0-.268.238L7.4,42.189a11.735,11.735,0,0,1-4.527-4.527l1.517-.735a.466.466,0,0,0,.216-.625,8.66,8.66,0,0,1-.876-3.835A.467.467,0,0,0,3.266,32H.467A.467.467,0,0,0,0,32.467a12.611,12.611,0,0,0,12.6,12.6.467.467,0,0,0,.467-.467V41.8A.467.467,0,0,0,12.6,41.331Z" transform="translate(0 -32)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                            <div className="dropdown-menu dropdown-menu-right calling-selection px-0 py-2">
                                                {showVoipCallBtn ? (<div style={{ pointerEvents: disab }} className="header_calling_drp border-bottom dropdown-item d-flex align-items-center" onClick={this.startVoipCall}>
                                                    <span>
                                                        <svg id="Group_8365" data-name="Group 8365" xmlns="http://www.w3.org/2000/svg" width="20.728" height="20.728" viewBox="0 0 20.728 20.728">
                                                            <g id="Group_2760" data-name="Group 2760">
                                                                <path id="Path_2245" data-name="Path 2245" d="M19.987,46.805a13.741,13.741,0,0,1-6.085-1.39.727.727,0,0,0-.567-.034.747.747,0,0,0-.425.378l-1.164,2.409a18.62,18.62,0,0,1-7.184-7.184L6.97,39.817a.739.739,0,0,0,.342-.992,13.741,13.741,0,0,1-1.39-6.085.741.741,0,0,0-.74-.74H.74a.741.741,0,0,0-.74.74A20.01,20.01,0,0,0,19.987,52.728a.741.741,0,0,0,.74-.74V47.546A.741.741,0,0,0,19.987,46.805Z" transform="translate(0 -32)" fill="#474747" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <div className="call-para">
                                                        <h4>Call via Livechat</h4>
                                                        {/* <p>Free on livechat</p> */}
                                                    </div>
                                                </div>) : (null)}
                                                {showPhoneCallBtn ? (<div className="header_calling_drp dropdown-item d-flex align-items-center" onClick={this.startCall}>
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.999" height="20.728" viewBox="0 0 20.999 20.728">
                                                            <path id="Union_11" data-name="Union 11" d="M15651,2472.739a.738.738,0,0,1,.739-.74h4.444a.738.738,0,0,1,.735.74,13.721,13.721,0,0,0,1.393,6.089.739.739,0,0,1-.343.991l-2.406,1.164a18.605,18.605,0,0,0,7.186,7.186l1.16-2.411a.774.774,0,0,1,.425-.378.728.728,0,0,1,.566.035,13.725,13.725,0,0,0,6.089,1.388.745.745,0,0,1,.739.743v4.44a.741.741,0,0,1-.739.74A20.009,20.009,0,0,1,15651,2472.739Zm15.96,9.227v-.008a1.037,1.037,0,1,1,2.073,0v.008a1.037,1.037,0,1,1-2.073,0Zm2.966-2.973v-.008a1.037,1.037,0,1,1,2.073,0v.008a1.037,1.037,0,1,1-2.073,0Zm-2.966,0v-.008a1.037,1.037,0,1,1,2.073,0v.008a1.037,1.037,0,1,1-2.073,0Zm-2.961,0v-.008a1.034,1.034,0,1,1,2.068,0v.008a1.034,1.034,0,1,1-2.068,0Zm5.927-2.973v0a1.037,1.037,0,1,1,2.073,0v0a1.037,1.037,0,0,1-2.073,0Zm-2.966,0v0a1.037,1.037,0,1,1,2.073,0v0a1.037,1.037,0,0,1-2.073,0Zm-2.961,0v0a1.034,1.034,0,1,1,2.068,0v0a1.034,1.034,0,1,1-2.068,0Zm5.927-2.978v0a1.037,1.037,0,0,1,2.073,0v0a1.037,1.037,0,1,1-2.073,0Zm-2.966,0v0a1.037,1.037,0,0,1,2.073,0v0a1.037,1.037,0,1,1-2.073,0Zm-2.961,0v0a1.034,1.034,0,1,1,2.068,0v0a1.034,1.034,0,1,1-2.068,0Z" transform="translate(-15651.001 -2471.999)" fill="#474747" />
                                                        </svg>
                                                    </span>
                                                    <div className="call-para">
                                                        <h4>{this.context.currentVisitor.client_phone}</h4>
                                                        {/* <p>Mobile</p> */}
                                                    </div>
                                                </div>) : (null)}
                                            </div>
                                        </li>
                                            <li style={{ display: "none" }} id="headerbtn-mute" className="nav-item list-inline-item">
                                                <span style={{ cursor: "pointer" }} data-tip="Mute Call" className="nav-link text-muted px-3">
                                                    <svg id="voice" xmlns="http://www.w3.org/2000/svg" width="11.731" height="15.063" viewBox="0 0 11.731 19.063">
                                                        <g id="Group_2898" data-name="Group 2898" transform="translate(0)">
                                                            <path id="Path_2297" data-name="Path 2297" d="M34.475,14.454a3.408,3.408,0,0,0,3.4-3.4V3.4a3.4,3.4,0,0,0-6.808,0V11.05A3.408,3.408,0,0,0,34.475,14.454Z" transform="translate(-28.609)" fill="#fff" />
                                                            <path id="Path_2298" data-name="Path 2298" d="M29.925,45.715h-.678a.4.4,0,0,0-.394.394V47.5a4.4,4.4,0,0,1-8.8,0V46.109a.4.4,0,0,0-.394-.394h-.678a.4.4,0,0,0-.394.394V47.5a5.949,5.949,0,0,0,5.132,5.819V54.3H20.693a.4.4,0,0,0-.394.394v.678a.4.4,0,0,0,.394.394h7.521a.4.4,0,0,0,.394-.394v-.678a.4.4,0,0,0-.394-.394H25.187v-.982A5.949,5.949,0,0,0,30.319,47.5V46.109A.394.394,0,0,0,29.925,45.715Z" transform="translate(-18.588 -36.7)" fill="#fff" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </li>
                                            <li style={{ display: "none" }} id="headerbtn-unmute" className="nav-item list-inline-item">
                                                <span style={{ cursor: "pointer" }} data-tip="Unmute Call" className="nav-link text-muted px-3">
                                                    <svg id="mute-microphone_copy" data-name="mute-microphone copy" xmlns="http://www.w3.org/2000/svg" width="15.48" height="15.063" viewBox="0 0 15.48 19.063">
                                                        <g id="Group_2897" data-name="Group 2897" transform="translate(0)">
                                                            <path id="Path_2294" data-name="Path 2294" d="M43.955,45.851v-2.5l-5.316,5.316a3.4,3.4,0,0,0,5.316-2.814Z" transform="translate(-32.81 -34.801)" fill="#fff" />
                                                            <path id="Path_2295" data-name="Path 2295" d="M36.89,45.715h-.678a.4.4,0,0,0-.394.394V47.5a4.4,4.4,0,0,1-6.876,3.634l-1.034,1.034a5.763,5.763,0,0,0,2.778,1.151V54.3H27.658a.4.4,0,0,0-.394.394v.678a.4.4,0,0,0,.394.394h7.521a.4.4,0,0,0,.394-.394v-.678a.4.4,0,0,0-.394-.394H32.152v-.982A5.949,5.949,0,0,0,37.284,47.5V46.109A.394.394,0,0,0,36.89,45.715Z" transform="translate(-23.679 -36.7)" fill="#fff" />
                                                            <path id="Path_2296" data-name="Path 2296" d="M24.245,1.517a1.085,1.085,0,0,0-1.534,0L20.226,4V3.4a3.4,3.4,0,1,0-6.808,0V10.81l-.9.9a4.384,4.384,0,0,1-.1-.912V9.409a.4.4,0,0,0-.394-.394h-.678a.4.4,0,0,0-.394.394V10.8a5.686,5.686,0,0,0,.4,2.077L9.4,14.828a1.085,1.085,0,0,0,1.534,1.534L24.244,3.051A1.084,1.084,0,0,0,24.245,1.517Z" transform="translate(-9.082)" fill="#fff" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </li>
                                            <li style={{ display: "none" }} id="button-hangup" onClick={this.endCall} className="nav-item list-inline-item 2">
                                                <span style={{ cursor: "pointer" }} data-tip="End Call" className="text-muted calling-circle-left reject-red">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.048" height="16.048" viewBox="0 0 20.048 20.048">
                                                        <g id="telephone" transform="translate(6.186 4.052)">
                                                            <g id="Group_2761" data-name="Group 2761" transform="translate(13.862 5.972) rotate(135)">
                                                                <g id="Group_2760" data-name="Group 2760">
                                                                    <path id="Path_2245" data-name="Path 2245" d="M13.67,10.126a9.4,9.4,0,0,1-4.162-.951.5.5,0,0,0-.388-.023.511.511,0,0,0-.291.258l-.8,1.647A12.734,12.734,0,0,1,3.121,6.144l1.646-.8A.506.506,0,0,0,5,4.668,9.4,9.4,0,0,1,4.05.506.506.506,0,0,0,3.544,0H.506A.506.506,0,0,0,0,.506a13.685,13.685,0,0,0,13.67,13.67.506.506,0,0,0,.506-.506V10.632A.506.506,0,0,0,13.67,10.126Z" fill="#fff" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>
                                            </li></>) : (null)}

                                        {this.context.zoom_status === 1 && (<li className="nav-item list-inline-item" >
                                            <span onClick={this.openMeetingForm} className="nav-link text-muted cursor-pointer zoomCall">
                                                <img alt="" src={require("../../../../assets/images/zoom-icon.svg")} />
                                            </span>
                                        </li>)}

                                        {((isWhatsAppCall || isFBChat || isTDMChat || isInstaChat) && !this.context.isMobileDevice && this.state.ticketingEnabled && !this.state.isAutoTicketing && this.state.deskPaymentStatus) ? (<li id="headerbtn-ticketing" onClick={() => this.showTicketing()} className="nav-item list-inline-item d-none d-xl-block position-relative">
                                            <span className="nav-link text-muted px-3" data-tip="Create Ticket">
                                                {/* <img alt="" src={require("../../../../assets/images/livechat/add-message.svg")} /> */}
                                                <svg id="Group_1853" data-name="Group 1853" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20.321 20.346">
                                                    <g id="Group_1852" data-name="Group 1852" transform="translate(0)">
                                                        <circle id="Ellipse_82" data-name="Ellipse 82" cx="0.794" cy="0.794" r="0.794" transform="translate(13.574 15.584)" fill="#fff" />
                                                        <circle id="Ellipse_83" data-name="Ellipse 83" cx="0.794" cy="0.794" r="0.794" transform="translate(11.748 5.343)" fill="#fff" />
                                                        <path id="Path_1730" data-name="Path 1730" d="M19.842,12.408h.794V7.646H18.611a1.587,1.587,0,0,1-.731-3l.676-.352L16.534,0,.333,7.642l0,0H.314v4.763h.794a1.588,1.588,0,1,1,0,3.175H.314v4.763H20.635V15.584h-.794a1.588,1.588,0,1,1,0-3.175Zm-8.725-8.1a.793.793,0,0,0,1.434-.676l3.224-1.521.735,1.563a3.172,3.172,0,0,0-.647,3.968H4.047Zm7.932,12.76v1.688H15.476a.794.794,0,1,0-1.588,0H1.9V17.071a3.176,3.176,0,0,0,0-6.15V9.233H13.888a.794.794,0,0,0,1.588,0h3.572v1.688a3.176,3.176,0,0,0,0,6.15Z" transform="translate(-0.314)" fill="#fff" />
                                                        <circle id="Ellipse_84" data-name="Ellipse 84" cx="0.794" cy="0.794" r="0.794" transform="translate(13.574 10.821)" fill="#fff" />
                                                        <circle id="Ellipse_85" data-name="Ellipse 85" cx="0.794" cy="0.794" r="0.794" transform="translate(13.574 13.202)" fill="#fff" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </li>) : (null)}

                                        {((isWhatsAppCall || isFBChat || isTDMChat || isInstaChat) && !this.context.isMobileDevice) ? (<li id="headerbtn-noteslist" onClick={() => this.showStickyNotes()} className="nav-item list-inline-item d-none d-xl-block position-relative">
                                            <span className="nav-link text-muted px-3" data-tip="Notes">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.896" height="15.721" viewBox="0 0 12.896 15.721">
                                                    <g id="note" transform="translate(-46)">
                                                        <g id="Group_68" data-name="Group 68" transform="translate(46)">
                                                            <g id="Group_67" data-name="Group 67">
                                                                <path id="Path_243" data-name="Path 243" d="M57.514.921h-.461V.461a.461.461,0,0,0-.921,0V.921H54.29V.461a.461.461,0,0,0-.921,0V.921H51.527V.461a.461.461,0,0,0-.921,0V.921H48.763V.461a.461.461,0,1,0-.921,0V.921h-.461A1.383,1.383,0,0,0,46,2.3V14.339a1.383,1.383,0,0,0,1.382,1.382H57.514A1.383,1.383,0,0,0,58.9,14.339V2.3A1.383,1.383,0,0,0,57.514.921Zm.461,13.418a.461.461,0,0,1-.461.461H47.382a.461.461,0,0,1-.461-.461V2.3a.461.461,0,0,1,.461-.461h.461V2.3a.461.461,0,1,0,.921,0V1.842h1.842V2.3a.461.461,0,0,0,.921,0V1.842h1.842V2.3a.461.461,0,0,0,.921,0V1.842h1.842V2.3a.461.461,0,0,0,.921,0V1.842h.461a.461.461,0,0,1,.461.461Z" transform="translate(-46)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_70" data-name="Group 70" transform="translate(47.842 4.207)">
                                                            <g id="Group_69" data-name="Group 69" transform="translate(0)">
                                                                <path id="Path_244" data-name="Path 244" d="M114.751,137h-8.29a.461.461,0,1,0,0,.921h8.29a.461.461,0,1,0,0-.921Z" transform="translate(-106 -137)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_72" data-name="Group 72" transform="translate(47.842 6.97)">
                                                            <g id="Group_71" data-name="Group 71" transform="translate(0)">
                                                                <path id="Path_245" data-name="Path 245" d="M114.751,227h-8.29a.461.461,0,1,0,0,.921h8.29a.461.461,0,1,0,0-.921Z" transform="translate(-106 -227)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_74" data-name="Group 74" transform="translate(47.842 9.733)">
                                                            <g id="Group_73" data-name="Group 73" transform="translate(0)">
                                                                <path id="Path_246" data-name="Path 246" d="M114.751,317h-8.29a.461.461,0,0,0,0,.921h8.29a.461.461,0,0,0,0-.921Z" transform="translate(-106 -317)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_76" data-name="Group 76" transform="translate(47.842 12.497)">
                                                            <g id="Group_75" data-name="Group 75" transform="translate(0)">
                                                                <path id="Path_247" data-name="Path 247" d="M114.751,407h-8.29a.461.461,0,0,0,0,.921h8.29a.461.461,0,1,0,0-.921Z" transform="translate(-106 -407)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </li>) : (null)}

                                        {(isPhoneCall || isVoipCall || this.context.isMobileDevice || !this.state.ticketingEnabled || this.state.isAutoTicketing || !this.state.deskPaymentStatus) ? (null) : (<li id="headerbtn-ticketing" onClick={() => this.showTicketing()} className="nav-item list-inline-item d-none d-xl-block position-relative">
                                            <span className="nav-link text-muted px-3" data-tip="Create Ticket">
                                                {/* <img alt="" src={require("../../../../assets/images/livechat/add-message.svg")} /> */}
                                                <svg id="Group_1853" data-name="Group 1853" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20.321 20.346">
                                                    <g id="Group_1852" data-name="Group 1852" transform="translate(0)">
                                                        <circle id="Ellipse_82" data-name="Ellipse 82" cx="0.794" cy="0.794" r="0.794" transform="translate(13.574 15.584)" fill="#fff" />
                                                        <circle id="Ellipse_83" data-name="Ellipse 83" cx="0.794" cy="0.794" r="0.794" transform="translate(11.748 5.343)" fill="#fff" />
                                                        <path id="Path_1730" data-name="Path 1730" d="M19.842,12.408h.794V7.646H18.611a1.587,1.587,0,0,1-.731-3l.676-.352L16.534,0,.333,7.642l0,0H.314v4.763h.794a1.588,1.588,0,1,1,0,3.175H.314v4.763H20.635V15.584h-.794a1.588,1.588,0,1,1,0-3.175Zm-8.725-8.1a.793.793,0,0,0,1.434-.676l3.224-1.521.735,1.563a3.172,3.172,0,0,0-.647,3.968H4.047Zm7.932,12.76v1.688H15.476a.794.794,0,1,0-1.588,0H1.9V17.071a3.176,3.176,0,0,0,0-6.15V9.233H13.888a.794.794,0,0,0,1.588,0h3.572v1.688a3.176,3.176,0,0,0,0,6.15Z" transform="translate(-0.314)" fill="#fff" />
                                                        <circle id="Ellipse_84" data-name="Ellipse 84" cx="0.794" cy="0.794" r="0.794" transform="translate(13.574 10.821)" fill="#fff" />
                                                        <circle id="Ellipse_85" data-name="Ellipse 85" cx="0.794" cy="0.794" r="0.794" transform="translate(13.574 13.202)" fill="#fff" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </li>)}

                                        {(isPhoneCall || isVoipCall || this.context.isMobileDevice) ? (null) : (<li id="headerbtn-noteslist" onClick={() => this.showStickyNotes()} className="nav-item list-inline-item d-none d-xl-block position-relative">
                                            <span className="nav-link text-muted px-3" data-tip="Notes" >
                                                {/* <img alt="" src={require("../../../../assets/images/livechat/add-message.svg")} /> */}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.896" height="15.721" viewBox="0 0 12.896 15.721">
                                                    <g id="note" transform="translate(-46)">
                                                        <g id="Group_68" data-name="Group 68" transform="translate(46)">
                                                            <g id="Group_67" data-name="Group 67">
                                                                <path id="Path_243" data-name="Path 243" d="M57.514.921h-.461V.461a.461.461,0,0,0-.921,0V.921H54.29V.461a.461.461,0,0,0-.921,0V.921H51.527V.461a.461.461,0,0,0-.921,0V.921H48.763V.461a.461.461,0,1,0-.921,0V.921h-.461A1.383,1.383,0,0,0,46,2.3V14.339a1.383,1.383,0,0,0,1.382,1.382H57.514A1.383,1.383,0,0,0,58.9,14.339V2.3A1.383,1.383,0,0,0,57.514.921Zm.461,13.418a.461.461,0,0,1-.461.461H47.382a.461.461,0,0,1-.461-.461V2.3a.461.461,0,0,1,.461-.461h.461V2.3a.461.461,0,1,0,.921,0V1.842h1.842V2.3a.461.461,0,0,0,.921,0V1.842h1.842V2.3a.461.461,0,0,0,.921,0V1.842h1.842V2.3a.461.461,0,0,0,.921,0V1.842h.461a.461.461,0,0,1,.461.461Z" transform="translate(-46)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_70" data-name="Group 70" transform="translate(47.842 4.207)">
                                                            <g id="Group_69" data-name="Group 69" transform="translate(0)">
                                                                <path id="Path_244" data-name="Path 244" d="M114.751,137h-8.29a.461.461,0,1,0,0,.921h8.29a.461.461,0,1,0,0-.921Z" transform="translate(-106 -137)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_72" data-name="Group 72" transform="translate(47.842 6.97)">
                                                            <g id="Group_71" data-name="Group 71" transform="translate(0)">
                                                                <path id="Path_245" data-name="Path 245" d="M114.751,227h-8.29a.461.461,0,1,0,0,.921h8.29a.461.461,0,1,0,0-.921Z" transform="translate(-106 -227)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_74" data-name="Group 74" transform="translate(47.842 9.733)">
                                                            <g id="Group_73" data-name="Group 73" transform="translate(0)">
                                                                <path id="Path_246" data-name="Path 246" d="M114.751,317h-8.29a.461.461,0,0,0,0,.921h8.29a.461.461,0,0,0,0-.921Z" transform="translate(-106 -317)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_76" data-name="Group 76" transform="translate(47.842 12.497)">
                                                            <g id="Group_75" data-name="Group 75" transform="translate(0)">
                                                                <path id="Path_247" data-name="Path 247" d="M114.751,407h-8.29a.461.461,0,0,0,0,.921h8.29a.461.461,0,1,0,0-.921Z" transform="translate(-106 -407)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </li>)}

                                        {this.context.isMobileDevice ? (null) : (<li id="headerbtn-endchat" onClick={() => this.confirmEndChat()} className="nav-item list-inline-item d-none d-xl-block">
                                            <span className="nav-link text-muted px-3" data-tip="End Chat">
                                                <img alt="" src={require("../../../../assets/images/livechat/crose.svg")} />
                                            </span>
                                        </li>)}


                                        {((isWhatsAppCall || isFBChat || isTDMChat || isInstaChat) && !this.context.isMobileDevice) ? (<li id="headerbtn-otheroptions" className="nav-item list-inline-item d-none d-xl-block" >
                                            <span className="nav-link text-muted px-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-tip="More Options" >
                                                <img alt="" src={require("../../../../assets/images/livechat/more-vertical.svg")} style={{ 'height': '14px' }} />
                                            </span>
                                            <div className="dropdown-menu px-0 py-2 bb">
                                                {showTransfer > 1 ? (null) : (<span className="dropdown-item d-flex align-items-center " onClick={() => this.showOnlineAgents()}>Transfer</span>)}
                                                <span style={{ pointerEvents: disab }} className="dropdown-item d-flex align-items-center" onClick={() => this.showTranslater()}>Translate chat</span>
                                                <span className="dropdown-item d-flex align-items-center" onClick={() => this.emailTranscript()}>Email transcript</span>
                                                {this.context.currentVisitor && this.context.currentVisitor.client_email ? (<span className="dropdown-item d-flex align-items-center" onClick={() => this.showPastChat()}>View past chats</span>) : (null)}
                                            </div>
                                        </li>) : (null)}

                                        {(isPhoneCall || isVoipCall || this.context.isMobileDevice) ? (null) : (<li id="headerbtn-otheroptions" className="nav-item list-inline-item d-none d-xl-block dropdown" >
                                            <span className="nav-link text-muted px-3" id="dropdownMenuButtonMore" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-tip="More Options" data-place="left">
                                                <img alt="" src={require("../../../../assets/images/livechat/more-vertical.svg")} style={{ 'height': '14px' }} />
                                            </span>
                                            <div className="dropdown-menu px-0 py-2 aa" style={{ 'z-index': '99999' }}>
                                                {showTransfer > 1 || isSM ? (null) : (<span className="dropdown-item d-flex align-items-center " onClick={() => this.showOnlineAgents()}>Transfer</span>)}
                                                {/*<span className="dropdown-item d-flex align-items-center">Ban/Block visitor</span> */}
                                                <span style={{ pointerEvents: disab }} className="dropdown-item d-flex align-items-center" onClick={() => this.showTranslater()}>Translate chat</span>
                                                <span className="dropdown-item d-flex align-items-center" onClick={() => this.emailTranscript()}>Email transcript</span>
                                                <span className="dropdown-item d-flex align-items-center" onClick={() => this.viewVisitorPaths()}>View visitor path</span>
                                                <span className="dropdown-item d-flex align-items-center" onClick={() => this.showPastChat()}>View past chats</span>
                                                <span className="dropdown-item d-flex align-items-center" onClick={() => this.banUser()}>Ban User</span>
                                                {this.state.cobrowsingEnabled ? <span className="dropdown-item d-flex align-items-center" onClick={() => this.startCoBrowsing()}>CoBrowse</span> : (null)}
                                            </div>
                                        </li>)}

                                        {(isPhoneCall || isVoipCall || !this.context.isMobileDevice) ? (null) : (<li className={`nav-item list-inline-item d-block d-xl-none ${mobileUpper}`}>
                                            <div className="dropdown">
                                                <span className="nav-link text-muted px-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={4} height={18} viewBox="0 0 4 18"><g id="Group_12768" data-name="Group 12768" transform="translate(-1378.075 -219.72)"><rect id="Rectangle_1688" data-name="Rectangle 1688" width={4} height={4} transform="translate(1378.075 219.72)" fill="#fff" /><rect id="Rectangle_1689" data-name="Rectangle 1689" width={4} height={4} transform="translate(1378.075 226.72)" fill="#fff" /><rect id="Rectangle_1690" data-name="Rectangle 1690" width={4} height={4} transform="translate(1378.075 233.72)" fill="#fff" /></g></svg>
                                                </span>
                                                <div className="dropdown-menu px-0 py-2">

                                                    {this.agent.whatsapp_msg && this.context.currentVisitor.client_phone ? (<span className="dropdown-item d-flex align-items-center" onClick={this.showWhatsAppTemplates}>
                                                        WhatsApp
                                                    </span>) : (null)}
                                                    {showVoipCallBtn ? (<span className="dropdown-item d-flex align-items-center" onClick={this.startVoipCall}>
                                                        Call via Livechat
                                                    </span>) : (null)}
                                                    <span className="dropdown-item d-flex align-items-center" onClick={() => this.showAvailableAgents()}>
                                                        Add Agent To Chat
                                                    </span>
                                                    <span className="dropdown-item d-flex align-items-center" onClick={() => this.showStickyNotes()} >
                                                        Notes
                                                    </span>
                                                    <span className="dropdown-item d-flex align-items-center" onClick={() => this.confirmEndChat()} >
                                                        End Chat
                                                    </span>
                                                    {showTransfer <= 1 ? (<span className="dropdown-item d-flex align-items-center" onClick={() => this.showOnlineAgents()}>
                                                        Transfer
                                                    </span>) : (null)}
                                                    <span className="dropdown-item d-flex align-items-center" onClick={() => this.showTranslater()}>
                                                        Translate chat
                                                    </span>
                                                    <span className="dropdown-item d-flex align-items-center" onClick={() => this.emailTranscript()}>
                                                        Email transcript
                                                    </span>
                                                    <span className="dropdown-item d-flex align-items-center" onClick={() => this.viewVisitorPaths()}>
                                                        View Visitor Path
                                                    </span>
                                                    <span className="dropdown-item d-flex align-items-center" onClick={() => this.showPastChat()}>
                                                        View past chats
                                                    </span>
                                                    <span className="dropdown-item d-flex align-items-center" onClick={() => this.banUser()}>
                                                        Ban User
                                                    </span>
                                                </div>
                                            </div>
                                        </li>)}
                                        {this.context.incoming_guide === false && this.context.incoming_guide_step === 2 && !this.context.isMobileDevice ? (<div className="tour_guide dd">
                                            <p className="title">Step 3 of 3</p>
                                            <p className="text-center">Click on this button to end the chat.</p>
                                            <button className="end-btn" onClick={() => { this.endGuideTour('incoming', 'incoming_guide') }}>End tour</button></div>) : (null)}

                                        {this.context.incoming_guide === false && this.context.incoming_guide_step === 2 && this.context.isMobileDevice ? (<div className="tour_guide mobile-guide">
                                            <p className="title">Step 3 of 3</p>
                                            <p className="text-center">Click on this button to end the chat.</p>
                                            <button className="end-btn" onClick={() => { this.endGuideTour('incoming', 'incoming_guide') }}>End tour</button></div>) : (null)}

                                    </ul>

                                </div>) : (chatPickStatusTransaction.status === "close" ? (
                                    <div className="col-3 col-xl-6 text-right mob-pd-0">
                                        <ul className="nav justify-content-end align-items-center">
                                            {(isPhoneCall || isVoipCall || this.context.isMobileDevice || !this.state.ticketingEnabled || this.state.isAutoTicketing || !this.state.deskPaymentStatus) ? (null) : (<li id="headerbtn-ticketing" onClick={() => this.showTicketing()} className="nav-item list-inline-item d-none d-xl-block position-relative">
                                                <span className="nav-link text-muted px-3" data-tip="Create Ticket">
                                                    {/* <img alt="" src={require("../../../../assets/images/livechat/add-message.svg")} /> */}
                                                    <svg id="Group_1853" data-name="Group 1853" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20.321 20.346">
                                                        <g id="Group_1852" data-name="Group 1852" transform="translate(0)">
                                                            <circle id="Ellipse_82" data-name="Ellipse 82" cx="0.794" cy="0.794" r="0.794" transform="translate(13.574 15.584)" fill="#fff" />
                                                            <circle id="Ellipse_83" data-name="Ellipse 83" cx="0.794" cy="0.794" r="0.794" transform="translate(11.748 5.343)" fill="#fff" />
                                                            <path id="Path_1730" data-name="Path 1730" d="M19.842,12.408h.794V7.646H18.611a1.587,1.587,0,0,1-.731-3l.676-.352L16.534,0,.333,7.642l0,0H.314v4.763h.794a1.588,1.588,0,1,1,0,3.175H.314v4.763H20.635V15.584h-.794a1.588,1.588,0,1,1,0-3.175Zm-8.725-8.1a.793.793,0,0,0,1.434-.676l3.224-1.521.735,1.563a3.172,3.172,0,0,0-.647,3.968H4.047Zm7.932,12.76v1.688H15.476a.794.794,0,1,0-1.588,0H1.9V17.071a3.176,3.176,0,0,0,0-6.15V9.233H13.888a.794.794,0,0,0,1.588,0h3.572v1.688a3.176,3.176,0,0,0,0,6.15Z" transform="translate(-0.314)" fill="#fff" />
                                                            <circle id="Ellipse_84" data-name="Ellipse 84" cx="0.794" cy="0.794" r="0.794" transform="translate(13.574 10.821)" fill="#fff" />
                                                            <circle id="Ellipse_85" data-name="Ellipse 85" cx="0.794" cy="0.794" r="0.794" transform="translate(13.574 13.202)" fill="#fff" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </li>)}
                                            <li onClick={() => this.confirmEndChat()} className="nav-item list-inline-item d-xl-block">
                                                <span className="nav-link text-muted px-3" data-tip="End Chat">
                                                    <img alt="" src={require("../../../../assets/images/livechat/crose.svg")} />
                                                </span>
                                            </li>
                                        </ul>
                                    </div>) : (null))}
                                {chatPickStatusTransaction.status === "start" ? (<div className="col-4 col-xl-6 text-right pl-0">
                                    <ul className="nav justify-content-end align-items-center">
                                        {this.context.currentVisitor.id.indexOf("TEMP") === -1 ? (<><li onClick={() => this.showStickyNotes()} className="nav-item list-inline-item d-none d-xl-block position-relative">
                                            <span className="nav-link text-muted px-3" data-tip="Show Notes">
                                                {/* <img alt="" src={require("../../../../assets/images/livechat/add-message.svg")} /> */}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.896" height="15.721" viewBox="0 0 12.896 15.721">
                                                    <g id="note" transform="translate(-46)">
                                                        <g id="Group_68" data-name="Group 68" transform="translate(46)">
                                                            <g id="Group_67" data-name="Group 67">
                                                                <path id="Path_243" data-name="Path 243" d="M57.514.921h-.461V.461a.461.461,0,0,0-.921,0V.921H54.29V.461a.461.461,0,0,0-.921,0V.921H51.527V.461a.461.461,0,0,0-.921,0V.921H48.763V.461a.461.461,0,1,0-.921,0V.921h-.461A1.383,1.383,0,0,0,46,2.3V14.339a1.383,1.383,0,0,0,1.382,1.382H57.514A1.383,1.383,0,0,0,58.9,14.339V2.3A1.383,1.383,0,0,0,57.514.921Zm.461,13.418a.461.461,0,0,1-.461.461H47.382a.461.461,0,0,1-.461-.461V2.3a.461.461,0,0,1,.461-.461h.461V2.3a.461.461,0,1,0,.921,0V1.842h1.842V2.3a.461.461,0,0,0,.921,0V1.842h1.842V2.3a.461.461,0,0,0,.921,0V1.842h1.842V2.3a.461.461,0,0,0,.921,0V1.842h.461a.461.461,0,0,1,.461.461Z" transform="translate(-46)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_70" data-name="Group 70" transform="translate(47.842 4.207)">
                                                            <g id="Group_69" data-name="Group 69" transform="translate(0)">
                                                                <path id="Path_244" data-name="Path 244" d="M114.751,137h-8.29a.461.461,0,1,0,0,.921h8.29a.461.461,0,1,0,0-.921Z" transform="translate(-106 -137)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_72" data-name="Group 72" transform="translate(47.842 6.97)">
                                                            <g id="Group_71" data-name="Group 71" transform="translate(0)">
                                                                <path id="Path_245" data-name="Path 245" d="M114.751,227h-8.29a.461.461,0,1,0,0,.921h8.29a.461.461,0,1,0,0-.921Z" transform="translate(-106 -227)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_74" data-name="Group 74" transform="translate(47.842 9.733)">
                                                            <g id="Group_73" data-name="Group 73" transform="translate(0)">
                                                                <path id="Path_246" data-name="Path 246" d="M114.751,317h-8.29a.461.461,0,0,0,0,.921h8.29a.461.461,0,0,0,0-.921Z" transform="translate(-106 -317)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_76" data-name="Group 76" transform="translate(47.842 12.497)">
                                                            <g id="Group_75" data-name="Group 75" transform="translate(0)">
                                                                <path id="Path_247" data-name="Path 247" d="M114.751,407h-8.29a.461.461,0,0,0,0,.921h8.29a.461.461,0,1,0,0-.921Z" transform="translate(-106 -407)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </li>
                                            <li className="nav-item list-inline-item d-none d-xl-block" >
                                                <span className="nav-link text-muted px-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-tip="More Options">
                                                    <img alt="" src={require("../../../../assets/images/livechat/more-vertical.svg")} style={{ 'height': '14px' }} />
                                                </span>
                                                <div className="dropdown-menu">
                                                    <span className="dropdown-item d-flex align-items-center" onClick={() => this.viewVisitorPaths()}>View visitor path</span>
                                                </div>
                                            </li></>) : (<li onClick={() => this.confirmEndChat()} className="nav-item list-inline-item d-xl-block">
                                                <span className="nav-link text-muted px-3" data-tip="End Chat">
                                                    <img alt="" src={require("../../../../assets/images/livechat/crose.svg")} />
                                                </span>
                                            </li>)}
                                    </ul>
                                </div>) : (null)}

                                {chatPickStatusTransaction.status === "serving" ? (<div className="col-4 col-xl-6 text-right pl-0">
                                    <ul className="nav justify-content-end align-items-center">
                                        <li className="nav-item list-inline-item d-none d-xl-block position-relative">
                                            {allAgents.length > 0 ? (
                                                <ul className="user-showings">
                                                    {allAgents.map((agent, index) =>
                                                        <li key={index}>{agent.profile_img ? (<img alt="" src={agent.profile_img} />) : agent.agent_short}
                                                            <div className="user-hover-show served-agents">
                                                                {/*  <div className="avatar">
                                                                <span>{agent.agent_short}</span>
                                                    </div> */}
                                                                <h6 className="text-name-hading name-chat-header">Agent : {agent.name}</h6>
                                                            </div>
                                                        </li>)}
                                                </ul>
                                            ) : (null)}
                                        </li>
                                    </ul>
                                </div>) : (null)}
                            </>)}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default React.memo(Header);