// SocketManager.js
import io from 'socket.io-client';

class SocketManager {
    constructor() {
        this.socket = null;
    }

    getSocket(url,agentId,ownerId) {
        if (!this.socket) {
            this.socket = io(url, {
                   query: { agentId: agentId , ownerId },
                 });
        }
        return this.socket;
    }
    checkActive(){
        if (this.socket) {
           return true
        }
        return false

    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }
}

const socketManager = new SocketManager();
export default socketManager;
